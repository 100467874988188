import React from 'react';
import { Grid, Typography, FormControl, TextField, Switch, FormControlLabel } from '@mui/material';

export const TargetRetailer = ({ selectedRetailerServiceType, modifiedCredential, setModifiedCredential }) => {
  return (
    <>
      {selectedRetailerServiceType.value === 15 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Advertiser Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.AdvertiserId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        AdvertiserId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 5 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Owner Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.TargetOwnerId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        TargetOwnerId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Security Questions</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.SecurityQuestions) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        SecurityQuestions: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 24 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Is RMS Credential</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControlLabel
              control={
                <Switch
                  checked={modifiedCredential.parameterValues?.isRmsCredential === 'true'}
                  onChange={(event) =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        isRmsCredential: event.target.checked.toString(),
                      },
                    })
                  }
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
