import React from 'react';
import { Grid, Typography, FormControl, TextField } from '@mui/material';

export const TikTokShopRetailer = ({ modifiedCredential, setModifiedCredential }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>OTP Secret Key</Typography>
        </Grid>
        <Grid item sm={10}>
          <FormControl fullWidth>
            <TextField
              type="text"
              autoComplete="off"
              value={
                (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.OtpSecretKey) ||
                ''
              }
              onChange={(e) =>
                setModifiedCredential({
                  ...modifiedCredential,
                  parameterValues: { ...modifiedCredential.parameterValues, OtpSecretKey: e.target.value },
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
