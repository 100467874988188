/* eslint-disable eqeqeq */
import React from 'react';
import { CardImg, CardImgOverlay, Card, CardTitle } from 'reactstrap';
import HarvestLogo from '../resources/harvest_logomark_white.svg';
import './WalmartMarketplaceApiAuth.css';

export default function WalmartMarketplaceApiAuth(props) {
  return (
    <>
      <Card>
        <CardImg
          alt="A sprawling field, glowing in the morning sun"
          src="https://picsum.photos/id/107/1300/165"
          style={{
            height: '165px',
          }}
          width="100%"
        />
        <CardImgOverlay>
          <img style={{ margin: '10px', height: '100px', width: '100px', float: 'right' }} alt="Logo" src={HarvestLogo} />
        </CardImgOverlay>
      </Card>
      <Card className="contentCard">
        <CardTitle tag="h1" sm={10} align="center">
          Walmart Marketplace Authorization .. Coming Soon!
        </CardTitle>
      </Card>
    </>
  );
}
